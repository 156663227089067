import Cookies from "js-cookie";
import { UserData, UserKredsCount } from "../../../src/types/user";
import { generateFileNameWithDate } from "../util/images";
import { UserType } from "../util/type";

export interface UserServiceApi {
    getUserKredsCount: (userId: number, token: string) => Promise<UserKredsCount>;
    getUserHilightsSentCount: (
        data: {
            userId: number,
            token: string,
            startDate: Date,
            endDate?: Date
        }
    ) => Promise<number>;
    createUser: (
        userData: {
            firstName: string,
            lastName: string,
            email: string,
            primarySchoolId: number,
            roleAtSchool?: string,
            userType: UserType,
            districtId: number,
            sendWelcomeEmail?: boolean,
        },
        token: string
    ) => Promise<{user?: UserData}>,
    updateUserData: (
        userId: number, 
        updatedUserData: {
            newFirstName?: string,
            newLastName?: string,
            newEmail?: string,
            newPrimarySchoolId?: number,
            newRoleAtSchool?: string,
            newProfilePhotoImagePath?: string | null,
            newUserType?: UserType
    },
        token: string
    ) => Promise<{user?: UserData}>;
    changeUserPassword: (userId: number, newPassword: string, token: string) => Promise<{token: string, user: UserData}>;
    uploadProfilePhoto: (userId: number, file: File, token: string) => Promise<string>;
    deactivateUser: (userId: number, token: string) => Promise<void>;
}

export interface UserServiceApiFactory {
    (): UserServiceApi;
}

export const UserServiceApi: UserServiceApiFactory = (): UserServiceApi => {
    return {
        getUserKredsCount: async (userId: number, token: string): Promise<UserKredsCount> => {
            const response = await fetch(`/api/v1/user/kreds/${userId}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                    'X-XSRF-TOKEN': Cookies.get('XSRF-TOKEN') || '',
                }
            });
            if (response.ok) {
                const body: {userKreds: UserKredsCount} = await response.json();
                if (body.userKreds) {
                    return body.userKreds;
                } else {
                    throw new Error('Error retrieving user kreds. Unexpected response body');
                }
            } else {
                throw new Error(`Error retrieving user kreds for user id: ${userId}. Error message: ${response.statusText}`);
            }
        },
        getUserHilightsSentCount: async (data: {
            userId: number,
            token: string,
            startDate: Date,
            endDate?: Date
        }): Promise<number> => {
            const {userId, token, startDate, endDate} = data;
            let requestUrl = `/api/v1/user/${userId}/hilightcountsent?startDate=${startDate}`;
            if (endDate) {
                requestUrl += `&endDate=${endDate}`;
            }
            const response = await fetch(requestUrl, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                    'X-XSRF-TOKEN': Cookies.get('XSRF-TOKEN') || '',
                }
            });
            if (response.ok) {
                const body: {countSent: number} = await response.json();
                return body.countSent;
            } else {
                throw new Error(`Error retrieving count of hilights sent for user id: ${userId}. Error message: ${response.statusText}`);
            }
        },
        createUser: async (
            userData:  {
                firstName: string,
                lastName: string,
                email: string,
                primarySchoolId: number,
                roleAtSchool?: string,
                userType: UserType,
                districtId: number,
                sendWelcomeEmail?: boolean,
            },
            token: string
        ): Promise<{user?: UserData,}> => {
            const response = await fetch(`/api/v1/user`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                    'X-XSRF-TOKEN': Cookies.get('XSRF-TOKEN') || '',
                },
                body: JSON.stringify({
                    districtId: userData.districtId,
                    firstName: userData.firstName,
                    lastName:  userData.lastName,
                    email: userData.email,
                    primarySchoolId: userData.primarySchoolId,
                    roleAtSchool: userData.roleAtSchool,
                    userType: userData.userType,
                    sendWelcomeEmail: userData.sendWelcomeEmail
                })
            });

            if (response.ok) {
                return response.json();
            } else {
                throw new Error(`Error creating user. Error message: ${response.statusText}`);
            }
        },
        updateUserData: async (
            userId: number,
            updatedUserData: {
                newFirstName?: string,
                newLastName?: string,
                newEmail?: string,
                newPrimarySchoolId?: number,
                newRoleAtSchool?: string,
                newProfilePhotoImagePath?: string  | null
                newUserType?: UserType
            },
            token: string
        ): Promise<{user?: UserData}> => {
             // API BEHAVIOR NOTE - in order to set the optional fields roleAtSchool or profilePhotoImagePath to undefined in the db, pass an empty string
            const { newFirstName, newLastName, newEmail, newUserType, newPrimarySchoolId, newRoleAtSchool, newProfilePhotoImagePath } = updatedUserData;
            const response = await fetch(`/api/v1/user/${userId}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                    'X-XSRF-TOKEN': Cookies.get('XSRF-TOKEN') || '',
                },
                body: JSON.stringify({
                    firstName: newFirstName,
                    lastName: newLastName,
                    roleAtSchool: newRoleAtSchool,
                    profilePhotoImagePath: newProfilePhotoImagePath,
                    email: newEmail,
                    userType: newUserType,
                    primarySchoolId: newPrimarySchoolId,
                })
            });

            if (response.ok) {
                return response.json();
            } else {
                throw new Error(`Error updating user data for user id: ${userId}. Error message: ${response.statusText}`);
            }
        },
        deactivateUser: async (userId: number, token: string): Promise<void> => {
            const response = await fetch(`/api/v1/user/${userId}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                    'X-XSRF-TOKEN': Cookies.get('XSRF-TOKEN') || '',
                },
                body: JSON.stringify({
                    deactivate: true
                })
            });

            if (response.ok) {
                return;
            } else {
                throw new Error(`Error deactivating user for user id: ${userId}. Error message: ${response.statusText}`);
            }
        },
        changeUserPassword: async (userId: number, newPassword: string, token: string): Promise<{token: string, user: UserData}> => {
            const response = await fetch(`/api/v1/changepassword?userid=${userId}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                    'X-XSRF-TOKEN': Cookies.get('XSRF-TOKEN') || '',
                },
                body: JSON.stringify({
                    newPassword
                })
            });
            if (response.ok) {
                return response.json();
            } else {
                throw new Error(`Error updating user password. Error message: ${response.statusText}`)
            }
        },
        uploadProfilePhoto: async (userId: number, file: File, token: string): Promise<string> => {
            const newFileName = generateFileNameWithDate(userId, file.name);
            const newFilePath = `profile_photos/${newFileName}`;

            // First get the signed s3 upload url
            const getSignedUrlResponse = await fetch(`/api/v1/image`, {
                method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                    'X-XSRF-TOKEN': Cookies.get('XSRF-TOKEN') || '',
                },
                body: JSON.stringify({
                    imageName: newFilePath
                })
            });
            if (!getSignedUrlResponse.ok) {
                throw new Error(`Error retrieving signed url to upload photo.`)
            }
            const result = await getSignedUrlResponse.json();
            const imageUploadUrl = result.imageUploadSignedUrl;
            if (!imageUploadUrl) {
                throw new Error(`Error retrieving signed url to upload photo. No signed url returned.`)
            }
                
            // Upload image to S3
            const s3UploadResponse = await fetch(imageUploadUrl, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'image/*',
                },
                body: file
            });

            // TO DO - if it failed because it expired, get another url
            if (!s3UploadResponse.ok) {
                throw new Error('Error uploading profile photo to S3: ' + s3UploadResponse.statusText)
            }

            return newFilePath;
        }
    }
}