import React, {useEffect, useState} from 'react';
import {  TextField, FormGroup, FormLabel, FormControlLabel, Switch, Checkbox, Button, Typography, Backdrop, CircularProgress, Autocomplete, Box } from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import StarsIcon from '@mui/icons-material/Stars';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import * as Sentry from "@sentry/react";

import { UserType } from '../../../util/type';
import { MainServiceApi } from '../../../services/mainService';
import { TokenTypeDisplayData } from '../../../../../src/types/recognition';
import { UserData, UserKredsCount } from '../../../../../src/types/user';
import { authSelector } from '../../../store/authSlice';

import './RecognitionForm.css';

export interface TeacherRecognitionSubmission {
    teacherId?: number,
    recognitionText?: string,
    anonymous?: boolean,
    submittedByTeacherId?: number,
    coreTokenId?: number,
}

export interface RecognitionFormProps {
    userId: number;
    schoolId: number;
    handleRecognitionFormSubmitted: (result: {success: boolean, bonusPointAwarded?: boolean}) => void;
    tokenTypes: TokenTypeDisplayData[];
    schoolStaff: UserData[];
    userKreds?: UserKredsCount;
    districtView: boolean;
    rewardsPointsDisabled: boolean;
}

export function RecognitionForm(props: RecognitionFormProps) {
    const {
        userId,
        schoolId,
        userKreds,
        handleRecognitionFormSubmitted,
        tokenTypes,
        schoolStaff,
        districtView,
        rewardsPointsDisabled,
    } = props;
    // Navigation
    const navigate = useNavigate();
    
    const authDataStore = useSelector(authSelector);
	const { user, token } = authDataStore;

    // Logged in User
	if (!user) {
		navigate('/login');
	}
    const userIsFreeUser = user?.userType === UserType.Free;

    // Recognition Form state
    const [isLoadingFormFields, setIsLoadingFormFields] = useState(true);
    const [teacherList, setTeacherList] = useState<UserData[]>([]);
    const [anonymous, setAnonymous] = useState<boolean>(false);
    const [recognitionText, setRecognitionText] = useState<string>('');
    const [selectedTeacher, setSelectedTeacher] = useState<UserData | undefined>();
    const [selectedTokenId, setSelectedTokenId] = useState<number | undefined>(undefined);
    const [isSubmitting, setIsSubmitting ] = useState(false); // can probably use formstate for this
    
    // Does the user have points to give?
    const userIsOutOfPointsToGive = userKreds?.pointsToGive !== undefined && userKreds?.pointsToGive < 1 && user?.userType !== UserType.Admin; // Admin have unlimited points
    // Disable giving points if the user is out of points, is a free user, or if rewards points are disabled
    const givePointsDisabled = userIsOutOfPointsToGive || userIsFreeUser || rewardsPointsDisabled;

    const handleOnSubmit = async (): Promise<void> => {
        setIsSubmitting(true);
        const serviceApi = MainServiceApi();
        
        try {
            if (
                !selectedTeacher
                || !selectedTokenId
            ) {
                throw new Error('Missing data when trying to submit recognition');
            }
            const result = await serviceApi.createRecognition({
                schoolId: schoolId,
                userIdReceiver: selectedTeacher.id,
                userIdSender: userId,
                tokenTypeId: selectedTokenId,
                anonymous: anonymous,
                text: recognitionText,
                created: new Date(),
                isFree: userIsFreeUser,
                wasSentWithPoint: !givePointsDisabled
            }, token!);
    
            // reset the form
            setSelectedTeacher(undefined);
            if (tokenTypes.length > 0) {
                setSelectedTokenId(tokenTypes[0].id)
            } else {
                setSelectedTokenId(undefined);
            }
            setAnonymous(false);
            setRecognitionText('');
            
            // finally, reset Recognition page
            handleRecognitionFormSubmitted({success: true, bonusPointAwarded: result.awardBonusPoint});
        } catch (err) {
            // log the error
			Sentry.captureException(err);

            // finally, reset Recognition page with an error
            handleRecognitionFormSubmitted({success: false});
        }
        
        setIsSubmitting(false);        
    }

    const handleSelectToken = (token: TokenTypeDisplayData) => {
        setSelectedTokenId(token.id);
    }

    useEffect(() => {
        if (schoolStaff.length > 0) {
            // Take out the logged in user since they can't give recognition to themselves
            const filteredStaffList = schoolStaff.filter((staffUser) => staffUser.id !== user!.id);
            // Sort the list alphabetically
            const alphabeticalStaffList = _.sortBy(filteredStaffList, 'firstName');
            setTeacherList(alphabeticalStaffList);
            setIsLoadingFormFields(false);
        } else {
            setTeacherList([]);
            setIsLoadingFormFields(false);
        }
    }, [schoolStaff]);

    useEffect(() => {
        if (tokenTypes.length > 0) {
            // set the first token as selected by default
            const firstToken = tokenTypes[0];
            setSelectedTokenId(firstToken.id);
        }
    }, [tokenTypes])

    return (
        <Box>
            <div className='recognition-form-section'>
                <Backdrop
                    sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={isSubmitting || isLoadingFormFields}
                >
                        <CircularProgress color="inherit" />
                </Backdrop>
                <div className='send-recognition-form-item '>
                    <FormLabel className='recognition-form-label'>Who do you want to hilight?</FormLabel>
                    {/* TO DO - add a ? icon if user belongs to more than one school telling them to change at the drop down above. Or they can click on district to send a district hilight */}
                    <div className='teacher-select select-teacher-name'>
                        <Autocomplete
                            disablePortal
                            id="teacher-select"
                            options={teacherList}
                            value={selectedTeacher || null}
                            getOptionLabel={(option) =>  `${option.firstName} ${option.lastName}` }
                            renderInput={(params) => <TextField {...params} label="Select Colleague's Name" />}
                            onChange={(event: any, value: UserData | null) => {
                                setSelectedTeacher(value || undefined);
                            }}
                        />
                    </div>
                </div>
            </div>
            <div className='recognition-form-section'>

            <div className={'send-recognition-form-item select-token-type'}>
                <div>
                    <FormLabel>{`Select one of your ${districtView ? 'District' : 'School'}'s Core Values:`}</FormLabel>
                    {(userIsOutOfPointsToGive && !userIsFreeUser && !rewardsPointsDisabled) ?
                        <div className='recognition-out-of-tokens-alert'>
                            <ErrorOutlineIcon className='recognition-out-of-tokens-alert-icon' />
                            <div className='recognition-out-of-tokens-alert-text'>
                                <Typography>You are out of Points to Give, but you can still send a hilight! The recipient just wont receive a point that they can spend in the Rewards Marketplace with it. Don't worry, your Points to Give refill back to 25 every Sunday night!</Typography>
                            </div>
                        </div>
                    : <></>
                    }
                    <FormGroup className='select-token-list'>
                        {
                            tokenTypes.map((token) =>
                                <FormControlLabel
                                    control={<Checkbox className='select-token-checkbox' icon={<div className='kid-kred-icon-faded select-token-icon' ><StarsIcon /></div> } checkedIcon={<div className='select-token-icon' ><StarsIcon/></div>}
                                    />}
                                    label={<span className='select-token-label'><Typography className={`select-token-label-text ${token.id === selectedTokenId ? '' : 'select-token-label-disabled'}`}><b>{token.tokenCoreValue}</b>{` - ${token.tokenDisplay}`}</Typography></span>}
                                    id={token.id.toString()}
                                    checked={token.id === selectedTokenId}
                                    key={token.id}
                                    onClick={()=> handleSelectToken(token)}
                                />
                            )
                        }
                    </FormGroup>
                </div>
            </div>
            </div>

            <div className='send-recognition-form-item recognition-text-field '>
                <TextField 
                    id="recognition-text"
                    className='recognition-text'
                    label='Why do you want to hilight this person?'
                    variant="outlined"
                    value={recognitionText}
                    onChange={(data) => {
                        const newText = data.target?.value as string | undefined;
                        setRecognitionText(newText || '')
                    }}
                    multiline
                    rows={4}
                />
            </div>
            {!userIsFreeUser &&
                <div >
                    <Switch 
                        checked={anonymous}
                        onChange={() => {
                            setAnonymous(!anonymous);
                        }}
                    />
                    <FormLabel>Send Anonymously</FormLabel>

                </div>
            }
            <div className='recognition-form-submit'>
                <Button
                    variant="contained"
                    className='give-button'
                    onClick={handleOnSubmit}
                    disabled={(selectedTeacher == undefined) || isSubmitting || (!recognitionText || recognitionText.length === 0)} // only allow submit if teacher has been selected and text is populated
                >Give</Button>
            </div>
        </Box>
    )
}