import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Box, CircularProgress, List, ListItem, Pagination, Typography } from '@mui/material';
import * as Sentry from "@sentry/react";

import { RecognitionData } from '../../../../../src/types/recognition';
import FeedListItem from './FeedListItem';
import { MainServiceApi } from '../../../services/mainService';
import { authSelector } from '../../../store/authSlice';

import './FeedList.css';

export interface FeedListProps {
    schoolId?: number;
    districtId?: number;
    userId: number;
    recognitionType: RecognitionType;
    startDate: Date;
    endDate?: Date;
    pageSize?: number;
    disabledForFreeUser?: boolean;
}

// Duplicated in recognition types in service src folder, but for some reason trying to import an enum from there threw an error even though we are importing other types from there
export enum RecognitionType {
    UserReceived,
    UserSent,
    School,
    District
}


export default function FeedList(props: FeedListProps) {
    const { schoolId, districtId, userId, recognitionType, startDate, endDate, pageSize, disabledForFreeUser } = props;

    // Default page size is 15
    const actualPageSize = pageSize || 15;

    const [recognitionList, setRecognitionList] = useState<RecognitionData[] | undefined>(undefined);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [hilightCount, setHilightCount] = useState(0);

    const authDataStore = useSelector(authSelector);
    const { token } = authDataStore;

    let hilightCountText = '';
    switch(recognitionType) {
        case RecognitionType.District:
            hilightCountText = 'Your District has sent';
            break;
        case RecognitionType.School:
            hilightCountText = 'Together, you and your colleagues have sent';
            break;
        case RecognitionType.UserReceived:
            hilightCountText = 'You have received';
            break;
        case RecognitionType.UserSent:
            hilightCountText = 'You have sent';
            break;
    }

    const getRecognitionPage = async (page: number) => {
        const mainServiceApi = MainServiceApi();	
        try {
            const recognitionResponse = await mainServiceApi.getRecognitionPaginated({
                userId,
                page,
                pageSize: actualPageSize,
                recognitionType,
                token: token!,
                schoolId: recognitionType !== RecognitionType.School ? undefined : schoolId,
                districtId,
                startDate,
                endDate
            });

            if (recognitionResponse.recognition) {
                setRecognitionList(recognitionResponse.recognition);
                setCurrentPage(recognitionResponse.currentPage);
                setTotalPages(recognitionResponse.totalPages);
                setHilightCount(recognitionResponse.count);
            } else {
                setRecognitionList([]);
                setCurrentPage(1);
                setTotalPages(1);
                setHilightCount(0);
            }
            
        } catch (err) {
            // log the error
			Sentry.captureException(err);
        }
    }

    useEffect(() => {
        getRecognitionPage(currentPage);
    }, [schoolId, recognitionType, startDate, endDate])

    const handleChangePage = async (newPage: number): Promise<void> => {
        // Scroll to the top of the page
        const element = document.getElementById('main-box');
        element?.scrollTo(0,0);

        // Load new recognition
        await getRecognitionPage(newPage);
    }
    
    return (
        <Box className='paginated-feed-list-box'>
            {hilightCount > 0 && 
                <Typography>
                    {hilightCountText } <span className='feed-number-of-hilights'>{hilightCount}</span> {<span>{hilightCount === 1 ? ' hilight' : ' hilights'}</span>} this school year!
                </Typography>
            }
            {disabledForFreeUser ?
                <div>
                    <Typography>The School feed is only enabled when your school or district has purchased the premium version of Hilight. Want to learn more or request more information? Visit our <a href='https://www.hilightedu.com'>website</a>. </Typography>
                </div>

                : 

                recognitionList === undefined ? (<CircularProgress />) : 
                    (recognitionList!.length > 0 ?
                        <div>
                            <List>
                                {
                                    recognitionList!.map((recognitionItem) => 
                                        <ListItem key={recognitionItem.id}>
                                            <FeedListItem 
                                                recognitionData={recognitionItem}
                                                recognitionType={recognitionType}
                                            />
                                        </ListItem>
                                    )
                                }	
                            </List> 
                                {totalPages > 1 ?
                                    <div className='feed-list-pagination'>
                                        <Pagination 
                                            count={totalPages}
                                            page={currentPage}
                                            color="secondary"
                                            variant="outlined"
                                            onChange={(event, page) => handleChangePage(page)}
                                        />
                                    </div> : <></>
                                }
                        </div>
                        : <Typography className='no-data'>No data to display - start sending some hilights!</Typography>
                    )
            }
        </Box> 
    )
}