import React from 'react';

import { Avatar, Box } from '@mui/material';

import { RecognitionData } from '../../../../../src/types/recognition';
import { timeAgo } from '../../../util/time';
import { getUserAvatar } from '../../../util/images';

import './FeedListItem.css';
import { RecognitionType } from './FeedList';

export interface FeedListItemProps {
    recognitionData: RecognitionData
    recognitionType: RecognitionType
}

export default function FeedListItem(props: FeedListItemProps) {
    const { recognitionData, recognitionType } = props;

    const timeString = timeAgo.format(new Date(recognitionData.created));

    return (
        <div className='feed-list-item'>
            <Box className='feed-list-item-header'>
                <Avatar
                        className='feed-list-item-avatar'
                        {...getUserAvatar(recognitionData.userReceiverProfilePhotoImageUrl)}
                >{recognitionData.userReceiverName.firstName[0]}{recognitionData.userReceiverName.lastName[0]}</Avatar>
                <Box className='feed-header-box'>
                    <Box>
                        <p className='feed-header-text'>
                            <span className='feed-header-bold'>
                                {recognitionData.userReceiverName.firstName} {recognitionData.userReceiverName.lastName} </span> was shouted out for 
                            <span className='feed-header-bold'> {recognitionData.tokenType.tokenShortDisplay }</span>
                        </p>
                        {recognitionType !== RecognitionType.School && <span className='feed-header-school-name'>{recognitionData.userReceiverName.schoolName}</span>}
                    </Box>
                </Box>
            </Box>
            
            <Box className='feed-list-item-text'>
                <p className='feed-text-paragraph'>
                {!recognitionData.anonymous && <span className='feed-text-from'>{`From ${recognitionData.userSenderName.firstName} ${recognitionData.userSenderName.lastName} ` + (recognitionData.text ? ' : ' : '')}</span>}
                {recognitionData.text}
                </p>
                <p className='feed-header-time'>{timeString}</p>

            </Box>
        </div>
    )
}