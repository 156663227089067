import React, {useState, useEffect} from 'react';

import { Autocomplete, Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, IconButton, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import * as Sentry from "@sentry/react";
import { isMobile } from 'react-device-detect';

import { School, UserData } from '../../../../../../src/types/user';
import { UserType } from '../../../../util/type';
import { useAppSelector, useAppDispatch } from '../../../../store/hooks';
import { authSelector, updateUserData } from '../../../../store/authSlice';
import { UserServiceApi } from '../../../../services/userService';

import './EditUserPopup.css';

export interface EditUserPopupProps {
    userToUpdate?: UserData;
    districtId: number;
    districtSchools: School[];
    popupOpen: boolean;
    closePopup: () => void;
    resetUser: (data:{ user: UserData, newlyCreatedUser: boolean}) => void;
    reactivateUser: boolean;
}

export default function EditUserPopup(props: EditUserPopupProps) {
    const { userToUpdate, districtSchools, popupOpen, closePopup, resetUser, reactivateUser, districtId } = props;
    const [firstNameField, setFirstNameField] = useState<string | undefined>();
    const [lastNameField, setLastNameField] = useState<string | undefined>();
    const [emailField, setEmailField] = useState<string | undefined>();
    const [selectedSchool, setSelectedSchool ] = useState<School | undefined>();
    const [selectedUserType, setSelectedUserType ] = useState<string | undefined>('teacher');
    const [roleField, setRoleField] = useState<string | undefined>();

    const authDataStore = useAppSelector(authSelector);
	const dispatch =  useAppDispatch();
    const { token } = authDataStore;
    const currentLoggedInUser = authDataStore.user;

    // Loading and error handling
    const [updateInProgress, setUpdateInProgress] = useState(false);
    const [updateSuccessful, setUpdateSuccessful] = useState(false);
    const [errorUpdatingUser, setErrorUpdatingUser] = useState(false);
    
    const resetFields = () => {
        setUpdateInProgress(false);
        setUpdateSuccessful(false);
        setErrorUpdatingUser(false);
        setFirstNameField(undefined);
        setLastNameField(undefined);
        setEmailField(undefined);
        setSelectedSchool(undefined);
        setSelectedUserType('teacher');
        setRoleField(undefined);
    };

    useEffect(() => {
        // reset the popup
        setUpdateInProgress(false);
        setUpdateSuccessful(false);
        setErrorUpdatingUser(false);
        
        if (userToUpdate) {
            setFirstNameField(userToUpdate.firstName);
            setLastNameField(userToUpdate.lastName);
            setEmailField(userToUpdate.email);
            const usersCurrentPrimarySchool = districtSchools.find((school) => school.id === userToUpdate.primarySchoolId);
            setSelectedSchool(usersCurrentPrimarySchool);
            setSelectedUserType(userToUpdate.userType === UserType.Admin ? 'admin' : 'teacher');
            setRoleField(userToUpdate.roleAtSchool); 
        } else {
            resetFields();
        }
    }, [userToUpdate]);

    

    const getUserTypeFromDropdownOptions = (userTypeOption?: string ): UserType | undefined=>  {
        if (!userTypeOption) {
            return;
        }
        return userTypeOption === 'admin' ? UserType.Admin : UserType.Teacher;
    }

    const handleClickSave = async () => {
        const userService = UserServiceApi();
        setUpdateInProgress(true);
		try {
			// Update or Create the User Data
            if (!userToUpdate) {
                // create the user
                const result: {user?: UserData} = await userService.createUser({
                    firstName: firstNameField!,
                    lastName:  lastNameField!,
                    email: emailField!,
                    primarySchoolId: selectedSchool?.id!,
                    roleAtSchool: roleField!,
                    userType: getUserTypeFromDropdownOptions(selectedUserType)!, 
                    districtId,
                    sendWelcomeEmail: true // when users are created from this popup, we always send a welcome email
                }, token!);

                if (!result || !result.user) {
                    throw new Error('Created user not returned from request');
                }
                resetUser({user: result.user, newlyCreatedUser: true})
            } else {
                // Otherwise, update the user
                const newUserType = getUserTypeFromDropdownOptions(selectedUserType);
                const result: { user?: UserData} = await userService.updateUserData(
                    userToUpdate.id, 
                    {
                        newFirstName: firstNameField !== userToUpdate.firstName ? firstNameField : undefined, // set these fields to undefined if they haven't changed
                        newLastName: lastNameField !== userToUpdate.lastName ? lastNameField : undefined,
                        newEmail: emailField !== userToUpdate.email ? emailField : undefined,
                        newPrimarySchoolId: selectedSchool?.id !== userToUpdate.primarySchoolId ? selectedSchool?.id : undefined,
                        newRoleAtSchool: roleField !== userToUpdate.roleAtSchool ? roleField : undefined,
                        newUserType: newUserType !== userToUpdate.userType ? newUserType : undefined,
                    },
                    token!
                );

                if (!result || !result.user) {
                    throw new Error('Updated user not returned from request');
                }
                // If the updated user is the currently logged in user, then we need to make sure we reset with any updates to the currently logged in user
                if (result.user.id === currentLoggedInUser!.id) {
                    dispatch(updateUserData(result.user));
                }
                resetUser({user: result.user, newlyCreatedUser: false});
            }
            setUpdateSuccessful(true);
		} catch (err) {
            // show error message
            setErrorUpdatingUser(true);
			// log the error
			Sentry.captureException(err);
		}
        
        // handle showing loading
        setUpdateInProgress(false);
    }


    const handleClickClose = async () => {
        closePopup();
        resetFields();
    }

    if (errorUpdatingUser) {
        return (
            <Box>
                <Dialog open={popupOpen} onClose={handleClickClose}>
                    <DialogTitle>Whoops!</DialogTitle>
                    <IconButton
                        aria-label="close"
                        onClick={handleClickClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: 'grey',
                        }}
                        >
                        <CloseIcon />
                    </IconButton>
                    <DialogContent className={isMobile ? 'edit-user-popup-content-mobile' : 'edit-user-popup-content'}>
                        <Typography className='edit-user-popup-error-text'>Something went wrong when updating the user. Please refresh this page and try again. Hilight Support has been notified.</Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button 
                            variant='contained'
                            onClick={handleClickClose}
                            id='cancel-edit-user-button'
                        >
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            </Box>
        )
    }

    if (updateSuccessful) {
        return (
            <Box>
                <Dialog open={popupOpen} onClose={handleClickClose}>
                    <DialogTitle>{userToUpdate ? 'Edit User' : 'Create User'}</DialogTitle>
                    <IconButton
                        aria-label="close"
                        onClick={handleClickClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: 'grey',
                        }}
                        >
                        <CloseIcon />
                    </IconButton>
                    <DialogContent className={isMobile ? 'edit-user-popup-content-mobile' : 'edit-user-popup-content'}>
                        <Typography className='edit-user-popup-success-text'>{userToUpdate ? 'User updated successfully!' : 'User created successfully!'}</Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button 
                            variant='contained'
                            onClick={handleClickClose}
                            id='cancel-edit-user-button'
                        >
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            </Box>
        );
    }

    return (
        <Box>
            <Dialog open={popupOpen} onClose={handleClickClose}>
                <DialogTitle>{reactivateUser ? 'Reactivate User' : (userToUpdate ? 'Edit User' : 'Create User') }</DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClickClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: 'grey',
                    }}
                    >
                    <CloseIcon />
                </IconButton>
                <DialogContent className={isMobile ? 'edit-user-popup-content-mobile' : 'edit-user-popup-content'}>
                    {updateInProgress ? 
                        <Box className='edit-user-loading'><CircularProgress /> </Box>
                    : 
                    <div>
                        {(userToUpdate?.cleverUserId && !reactivateUser) && 
                            <Box className='edit-clever-user-warning-text'>
                                <Typography >This user is associated with a Clever account. Please note, if you change the user's 
                                    <span className='edit-user-bold-text'> First Name</span>, 
                                    <span className='edit-user-bold-text'> Last Name</span>, 
                                    <span className='edit-user-bold-text'> Email</span>, or 
                                    <span className='edit-user-bold-text'> Role</span>, the values will likely be overriden in the nightly sync with Clever. 
                                    However, you can change this user's <span className='edit-user-bold-text'>User Type</span> here, and it will NOT be overriden by the Clever sync.</Typography>
                            </Box>
                        }
                        {reactivateUser &&
                            <Typography className='reactivate-user-text'>
                                Please confirm that you want to reactivate this user and make any updates needed to their account information.
                            </Typography>}
                        {!userToUpdate &&
                            <div className='create-user-instructions'>
                                <span>{`Please provide the following information to create a new user. If you'd like to create users in bulk (more than one at a time), you can email a csv to `}</span>
                                <span className='create-users-bold-text'>support@hilightedu.com</span>
                                <span>. Please make sure you provide a first name, last name, email, and school or department for the user. The support team will create the new users for you!</span>
                            </div>
                        }
                        <TextField 
                            label='First Name'
                            variant='outlined'
                            className='edit-user-field'
                            value={firstNameField || ''}
                            type='text'
                            onChange={(e) => setFirstNameField(e.target.value)}
                        />
                        <TextField 
                            label='Last Name'
                            variant='outlined'
                            className='edit-user-field'
                            value={lastNameField || ''}
                            type='text'
                            onChange={(e) => setLastNameField(e.target.value)}
                        />
                        <TextField 
                            label='Email'
                            variant='outlined'
                            className='edit-user-field'
                            id='edit-user-email-field'
                            value={emailField || ''}
                            type='text'
                            onChange={(e) => setEmailField(e.target.value)}
                        />
                        <TextField 
                            label='Role'
                            variant='outlined'
                            className='edit-user-field'
                            id='edit-user-role-field'
                            value={roleField || ''}
                            type='text'
                            onChange={(e) => setRoleField(e.target.value)}
                        />
                        <Autocomplete
                            disablePortal
                            id='edit-user-school-field'
                            className='edit-user-field'
                            options={districtSchools}
                            value={selectedSchool || null}
                            getOptionLabel={(option) => option.name}
                            renderInput={(params) => <TextField {...params} label='Select School' />}
                            onChange={(event: any, value: School | null) => {
                                setSelectedSchool(value || undefined)
                            }}
                        />
                        <TextField
                            select
                            label='User Type'
                            id='edit-user-type-field'
                            value={selectedUserType}
                            className='edit-user-field'
                            onChange={(event: any) => {
                                const newValue = event.target.value;
                                if (newValue) {
                                    setSelectedUserType(newValue);
                                }
                            }}
                        >
                            <MenuItem key='admin' value='admin'>Admin</MenuItem>
                            <MenuItem key='teacher' value='teacher'> Standard</MenuItem>
                        </TextField>
                        {!userToUpdate &&
                            <Typography className='create-user-send-email-text'>When you click <span className='edit-user-bold-text'>Save</span>, an email will be sent to this new user with their login information and some instructions on how to get started using Hilight.</Typography>
                        }
                    </div>
                    }
                </DialogContent>
                <DialogActions>
                    <Button 
                        variant='contained'
                        onClick={handleClickSave}
                        disabled={
                            updateInProgress ||
                            !(firstNameField &&  firstNameField !== '' 
                            && lastNameField && lastNameField !== '' 
                            && emailField && emailField !== ''
                            && selectedSchool  
                            && selectedUserType)
                        }
                    >
                        {reactivateUser ? 'Reactivate' : 'Save'}
                    </Button>
                    <Button 
                        variant='contained'
                        onClick={handleClickClose}
                        disabled={updateInProgress}
                        id='cancel-edit-user-button'
                    >
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    )
}