import React, {useEffect, useState} from 'react';
import { useSelector } from 'react-redux';
import { Box } from '@mui/material';
import * as Sentry from "@sentry/react";

import './StandardUserData.css';
import { StandardUserData } from './StandardUserData';
import { AdminData } from './AdminData';
import { TokenTypeDisplayData } from '../../../../../src/types/recognition';
import { MainServiceApi } from '../../../services/mainService';
import { GetTeacherDataDashboard } from '../../../../../src/types/dataDashboard';
import { authSelector } from '../../../store/authSlice';
import { School } from '../../../../../src/types/user';
import { PermissionServiceApi } from '../../../services/permissionService';
import { UserType } from '../../../util/type';
import { SchoolYear } from '../../../../../src/util/dates';

import './Data.css';

export interface DataProps {
    isAdminUser: boolean;
    tokenTypes: TokenTypeDisplayData[],
    userId: number,
    currentSchool?: School,
}

export const ChartColors = ['#4285f4', '#59d54d', '#f900ff', '#9900ff', '#fbbd06', '#fb9901', '#796BA6', '#C900FF', '#FA5F83', '#FD9F24' , '#4EADA1', '#51B78C' ];
export const DefaultColor = '#FF8042';

export function Data(props: DataProps) {
    const { 
        isAdminUser,
        tokenTypes,
        userId,
        currentSchool
    } = props;

    const authDataStore = useSelector(authSelector);
    const { user, token } = authDataStore;

    const districtId = user!.districtId;
    const userIsFreeUser = user?.userType === UserType.Free;

    //Set empty defaults for charts
    const [standardUserDashboardData, setStandardUserDashboardData] = useState<GetTeacherDataDashboard>({
        schoolTokensEarnedByType: [],
        percentageUsersRecognizedByMonth: [],
        countRecognitionEarnedByMonth: [],
        countRecognitionGivenByMonth: [],
        districtTokensEarnedByType: []
    });
    const [tokenTypesChartLegend, setTokenTypesChartLegend] = useState<TokenTypesChartLegendItem[]>([]);
    const [districtTokenTypes, setDistrictTokenTyeps] = useState<TokenTypeDisplayData[]>([]);
    const [districtTokenTypesChartLegend, setDistrictTokenTypesChartLegend] =useState<TokenTypesChartLegendItem[]>([]);

    // School years for the drop down for the Personal data dashboard (will be slightly different for the Admin one)
    const [ timeRangeSelection, setTimeRangeSelection] = useState<string>('');
    const [ startDate, setStartDate ] = useState<Date>();
    const [ endDate, setEndDate ] = useState<Date | undefined>(); // leave empty by default so we get up to and including today
    const [ schoolYearOptions, setSchoolYearOptions] = useState<SchoolYear[]>([]);
    const getSchoolYearOptions = async (schoolId: number, token: string) => {
        try {
            const mainService = MainServiceApi();
            const schoolYears = await mainService.getSchoolYearsList({schoolId, token});
            setSchoolYearOptions(schoolYears);
            // By default, should be showing the current school year
            const currentSchoolYear = schoolYears.find(option => option.key === 'current');
            setTimeRangeSelection('current');
            setStartDate(currentSchoolYear?.startDate);
            setEndDate(undefined) // By default will get up and including to today if left undefined

        } catch (err) {
            // log the error
            Sentry.captureException(err);
        }
    }
    // Only re-fetch the school year options if the school id changes
    useEffect(() => {
        if (currentSchool) {
            getSchoolYearOptions(currentSchool.id, token!);
        }
    }, [currentSchool]);
    // Define the handler for the drop down here so we can pass it down
    const handleChangeSchoolYearSelection = (newValue?: string) => {
        const newSelectedSchoolYear = schoolYearOptions.find(option  => option.key === newValue);
        if (newSelectedSchoolYear) {
            setTimeRangeSelection(newSelectedSchoolYear.key);
            setStartDate(newSelectedSchoolYear.startDate);
            setEndDate(newSelectedSchoolYear.endDate);
        }
    }

    // District Permisisions
    const [allowCrossSchoolHilights, setAllowCrossSchoolHilights] = useState(false);
    const getDistrictLevelPermissions = async (districtId: number): Promise<{allowCrossSchoolHilights: boolean}> => {
		const permissionsServiceApi = PermissionServiceApi();

		try {
			const districtPermissions = await permissionsServiceApi.getPermissionsForDistrict(districtId, token!);
			const crossSchoolHilightsKey = 'CROSS_SCHOOL_HILIGHTS';
			const districtHasPermissionToSendCrossSchoolHilights = districtPermissions.find(permission => permission.permissionKey === crossSchoolHilightsKey);
			if (districtHasPermissionToSendCrossSchoolHilights) {
				setAllowCrossSchoolHilights(true);

                // get the district token types
                const mainServiceApi = MainServiceApi();
                const districTokenTypes = await mainServiceApi.getDistrictTokenTypes(districtId, token!);
                setDistrictTokenTyeps(districTokenTypes);
                return {allowCrossSchoolHilights: true};
			} else {
                setAllowCrossSchoolHilights(false);
                return {allowCrossSchoolHilights: false}
            } 
		} catch (err) {
			// log the error
			Sentry.captureException(err);

            return {allowCrossSchoolHilights: false};
		}
	}

    // Gather data for the dashboard
    useEffect(() => {
        if (currentSchool && startDate) {
            getStandardUserData({
                schoolId: currentSchool.id,
                usersId: userId,
                schoolYearStartDate: startDate,
                schoolYearEndDate: endDate,
            });
        }
    }, [currentSchool, userId, startDate]);

    // Get data for the dashboard
    const getStandardUserData = async (data: {
        schoolId: number
        usersId: number,
        schoolYearStartDate: Date,
        schoolYearEndDate?: Date,
    }) => {
        const { schoolId, usersId, schoolYearStartDate, schoolYearEndDate} = data
        const mainServiceApi = MainServiceApi();
        try {
            const permissions = await getDistrictLevelPermissions(districtId);
            
            const standardUserData = await mainServiceApi.getTeacherDataDashboard({
                userId: data.usersId,
                schoolId, 
                token: token!, 
                includeDistrictData: permissions.allowCrossSchoolHilights,
                startDate: schoolYearStartDate,
                endDate: schoolYearEndDate
            });
            if (standardUserData) {
                setStandardUserDashboardData(standardUserData);
            }
        } catch (err) {
            // log the error
            Sentry.captureException(err);
        }
        
    }

    // Format school token types if they change
    useEffect(() => {
        if (tokenTypes) {
            setTokenTypesChartLegend(formatTokenTypes(tokenTypes));
        }
        
    }, [tokenTypes]);

    // Format district token types if they change
    useEffect(() => {
        if (districtTokenTypes) {
            // Build the legend based off of the token types
            const districtTokenTypesChartLegendList: {
                id: string,
                color: string,
                value: string,
            }[] = districtTokenTypes.map((tokenType, index) => {
                return {
                    id: tokenType.id.toString(),
                    color: ChartColors[index % ChartColors.length],
                    value: tokenType.tokenCoreValue
                }
            });
            // Add the "Other" category
            districtTokenTypesChartLegendList.push({
                id: '-1',
                color: DefaultColor,
                value: 'Other'
            })
            setDistrictTokenTypesChartLegend(districtTokenTypesChartLegendList);
        } else {
            setDistrictTokenTypesChartLegend([]);
        }
        
        
    }, [districtTokenTypes]);

    return (
        <div className='teacher-data-page'>
            <Box className='page-heading'>Data Dashboard</Box>
            { isAdminUser ? 
                <Box>
                    <AdminData
                        userData={standardUserDashboardData}
                        tokenTypesChartLegend={tokenTypesChartLegend}
                        districtTokenTypesChartLegend={districtTokenTypesChartLegend}
                        currentSchool={currentSchool}
                        showDistrictData={allowCrossSchoolHilights}
                        disabledForFreeUser={userIsFreeUser}
                        personalSchoolYearOptions={schoolYearOptions}
                        personalHandleChangeSchoolYearSelection={handleChangeSchoolYearSelection}
                        personalSelectedSchoolYear={timeRangeSelection}
                    /> 
                </Box> : 
                <Box className='personal-data-page'>
                    <StandardUserData 
                        data={standardUserDashboardData}
                        tokenTypesChartLegend={tokenTypesChartLegend}
                        districtTokenTypesChartLegend={districtTokenTypesChartLegend}
                        showDistrictData={allowCrossSchoolHilights}
                        currentSchool={currentSchool}
                        disabledForFreeUser={userIsFreeUser}
                        schoolYearOptions={schoolYearOptions}
                        selectedSchoolYear={timeRangeSelection}
                        handleChangeSchoolYearSelection={handleChangeSchoolYearSelection}
                    /> 
                </Box> 
            }
        </div>
    )
}

export interface TokenTypesChartLegendItem {
    id: string,
    color: string,
    value: string,
}
export function formatTokenTypes(tokenTypes: TokenTypeDisplayData[]): TokenTypesChartLegendItem[] {
    // Build the legend based off of the token types
    const chartLegendList: {
        id: string,
        color: string,
        value: string,
    }[] = tokenTypes.map((tokenType, index) => {
        return {
            id: tokenType.id.toString(),
            color: ChartColors[index % ChartColors.length],
            value: tokenType.tokenCoreValue
        }
    });
    // Add the "Other" category
    chartLegendList.push({
        id: '-1',
        color: DefaultColor,
        value: 'Other'
    });

    return chartLegendList;
}