import React, { useState, useEffect } from 'react';
import { Box, Button, Grid, Typography } from '@mui/material';
import * as Sentry from "@sentry/react";
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import RefreshIcon from '@mui/icons-material/Refresh';
import _ from 'lodash';

import { useAppSelector } from '../../../../store/hooks';
import { authSelector } from '../../../../store/authSlice';

import './ManageRewardsTab.css';
import { ManageRewardsTable } from './ManageRewardsTable';
import { ManageRewardData } from '../../../../../../src/types/rewards';
import { School, UserData } from '../../../../../../src/types/user';
import { MainServiceApi } from '../../../../services/mainService';
import EditRewardPopup from './EditRewardPopup';
import DeactivateRewardPopup from './DeactivateRewardPopup';

export interface ManageRewardsTabProps {
    districtId: number;
    rewardsChanged?: () => void; // Used by Rewards tab
}

export function ManageRewardsTab(props: ManageRewardsTabProps) {
    const { districtId, rewardsChanged } = props;
    const authDataStore = useAppSelector(authSelector);
    const {token} = authDataStore;

    const [pageDataLoading, setPageDataLoading] = useState(false);
    const [districtSchools, setDistrictSchools] = useState<School[]>([]);
    const [activeRewards, setActiveRewards] = useState<ManageRewardData[]>([]);
    const [deactivatedRewards, setDeactivatedRewards] = useState<ManageRewardData[]>([]);
    const [districtRewardOwners, setDistrictRewardOwners] = useState<UserData[]>([]);
    const [districtUsers, setDistrictUsers] = useState<UserData[]>([]);

    // Default reward image
    const [defaultImageData, setDefaultImageData] = useState<{imagePath: string, imageUrl: string}>();

    // Load the data for the reward tables
    useEffect(() => {
        loadTableData(districtId);
    }, [districtId]);

    const loadTableData = async (districtId: number) => {
        setPageDataLoading(true);
        try {
            const mainServiceApi = MainServiceApi();
            const rewardsData = await mainServiceApi.getDistrictRewards({districtId, token: token!});
            if (rewardsData) {
                setActiveRewards(_.sortBy(rewardsData.activeRewards, ['schoolId', 'cost']));
                setDeactivatedRewards(rewardsData.deactivatedRewards);
                setDistrictRewardOwners(rewardsData.districtRewardOwners);
            } else {
                setActiveRewards([]);
                setDeactivatedRewards([]);
                setDistrictRewardOwners([]);
            }

            const schools = await mainServiceApi.getDistrictSchools(districtId, token!);
            if (schools) {
                setDistrictSchools(schools)
            } else {
                setDistrictSchools([]);
            }

            const districtUsers = await mainServiceApi.getDistrictUsers({districtId, token: token!});
            if (districtUsers) {
                setDistrictUsers(districtUsers);
            } else {
                setDistrictUsers([]);
            }

            const defaultRewardImageData: {imagePath: string, imageUrl: string} = await mainServiceApi.getDefaultRewardImage(token!);
            setDefaultImageData(defaultRewardImageData);

        } catch (err) {
            // log the error
            Sentry.captureException(err);
        }
        setPageDataLoading(false);
    }

    // Table Action Popups:
    const [selectedReward, setSelectedReward] = useState<ManageRewardData | undefined>();
    const [showEditRewardPopup, setShowEditRewardPopup] = useState(false);
    const [showDeactivateRewardPopup, setShowDeactivateRewardPopup] = useState(false);
    const [showReactivateRewardPopup, setShowReactivateRewardPopup] = useState(false);
    const handleClickActions = (data: {reward?: ManageRewardData, deactivate?: boolean, reactivate?: boolean}) => {
        setSelectedReward(data.reward);
        if (data.deactivate) {
            setShowDeactivateRewardPopup(true);
        } else if (data.reactivate) {
            setShowReactivateRewardPopup(true);
        } else {
            setShowEditRewardPopup(true);
        }
    }
    const closePopup = () => {
        setSelectedReward(undefined);
        // We can use the same function for all three popups, so just close all
        setShowEditRewardPopup(false);
        setShowDeactivateRewardPopup(false);
        setShowReactivateRewardPopup(false);
    }

    const handleResetUpdatedReward = (data: {reward: ManageRewardData, newlyCreatedReward: boolean}) => {
        // If the reward was created or updated, we need to update the Currrent Rewards list
        // We can just add it manually and not re-get the data if we have all the data we need
        // but if a new reward owner was added that hasn't been used in the table yet, then we need to re-get the data
        const rewardOwner = districtRewardOwners.find(user => user.id === data.reward.ownerUserId)
        if (!rewardOwner) {
            loadTableData(districtId);
        } else {
            //otherwise we can just update the single row in the table

            // if the reward was created
            if (data.newlyCreatedReward) {
                const newRows = activeRewards.concat(data.reward);
                setActiveRewards(_.sortBy(newRows, ['schoolId', 'cost' ]));
            } else { // or if the reward was updated
                // Update the row for the reward so it shows the new data
                const newRows = activeRewards.map((row) => {
                    if (row.id === data.reward.id) {
                        return data.reward;
                    } else {
                        return row;
                    }
                });
                setActiveRewards(newRows);
            }
        }
        
        if (rewardsChanged) {
            rewardsChanged();
        }
    }

    const handleResetDeactivatedReward = (deactivatedReward: ManageRewardData) => {
        // Remove the deactivated reward from the Current Rewards table
        const newRows = activeRewards.filter(row => row.id !== deactivatedReward.id);
        setActiveRewards(newRows);
        
        // Add the deactivated reward to the Deactivated Rewards table and sort by school then cost
        const newDeactivatedRewards = deactivatedRewards.concat(deactivatedReward);
        setDeactivatedRewards(_.sortBy(newDeactivatedRewards, ['schoolId', 'cost']));
        if (rewardsChanged) {
            rewardsChanged();
        }
    }

    const handleResetReactivatedReward = (data: {reward: ManageRewardData, newlyCreatedReward: boolean}) => {
        // Remove the reactivated reward from the Deactivated Rewards table
        const newDeactivatedRewards = deactivatedRewards.filter(row => row.id !== data.reward.id);
        setDeactivatedRewards(newDeactivatedRewards);

        // Add the reactivated reward to the Current Rewarrds table and sort by school then cost
        const newActiveRewards = activeRewards.concat(data.reward);
        setActiveRewards(_.sortBy(newActiveRewards, ['schoolId', 'cost']));
        if (rewardsChanged) {
            rewardsChanged();
        }
    }

    return (
        <Box>
            <EditRewardPopup 
                rewardToUpdate={selectedReward}
                districtSchools={districtSchools}
                districtUsers={districtUsers}
                popupOpen={showEditRewardPopup} 
                closePopup={closePopup}
                resetReward={handleResetUpdatedReward}
                reactivateReward={false}
                defaultImageData={defaultImageData}
            />
            <DeactivateRewardPopup 
                rewardToDeactivate={selectedReward}
                popupOpen={showDeactivateRewardPopup}
                closePopup={closePopup}
                handleRemoveReward={handleResetDeactivatedReward}
            />
            {/* Reactivate Reward Popup (also uses Edit Rewarrd Popup) */}
            <EditRewardPopup 
                rewardToUpdate={selectedReward}
                districtSchools={districtSchools}
                districtUsers={districtUsers}
                popupOpen={showReactivateRewardPopup} 
                closePopup={closePopup}
                resetReward={handleResetReactivatedReward}
                reactivateReward={true}
                defaultImageData={defaultImageData}
            />
            <Grid container spacing={3}>
                <Grid item xs={12} md={12}>
                    <div className='reward-admin-settings-grid-item-border'>
                        <div>
                            <Box className='current-rewards-header'>
                                <h3 className='reward-admin-settings-grid-item-header'>Current Rewards</h3>
                                <div className='create-reward-button-div'>
                                    <Button 
                                        id='create-reward-button' 
                                        variant='contained'
                                        startIcon={<AddIcon />}
                                        onClick={() => handleClickActions({})}
                                    >Create Reward</Button>
                                </div>
                            </Box>
                            <Typography className='current-rewards-instructions'>
                                You can use this table to 
                                <span className='create-users-bold-text'> Update </span>{`(`}<EditIcon sx={{fontSize: 'small'}}/>{`) or `}<span className='create-users-bold-text'>Deactivate</span>{` (`}<DeleteIcon sx={{fontSize: 'small'}}/>{`) `}
                                any of the rewards offered to your staff.
                                {districtSchools.length > 1 && <span><span>{` If you'd like to make any updates to district-wide rewards (rewards offered at all the schools in your district), you can email`}</span><span className='create-users-bold-text'> support@hilightedu.com</span><span> to help make bulk changes instead of updating the reward for each school individually.</span></span>}
                            </Typography>
                            <ManageRewardsTable 
                                districtRewardOwners={districtRewardOwners}
                                districtSchools={districtSchools}
                                rewardRows={activeRewards}
                                activeRewards={true}
                                rowsLoading={pageDataLoading}
                                handleClickAction={handleClickActions}
                            />
                        </div>
                    </div>
                </Grid>
                <Grid item xs={12} md={12} className='admin-settings-grid-item'>
                    <div className='admin-settings-grid-item-border'>
                        <div className='admin-settings-grid-item-content'>
                            <h3 className='admin-settings-grid-item-header'>Deactivated Rewards</h3>
                            <Typography className='current-users-instructions'>
                                You can use this table to 
                                <span className='create-users-bold-text'> Reactivate </span>{`(`}<RefreshIcon sx={{fontSize: 'small'}}/>{`) `}
                                any rewards that were previously deactivated if you'd like to make them available to your staff again.
                            </Typography>
                            <ManageRewardsTable 
                                districtRewardOwners={districtRewardOwners}
                                districtSchools={districtSchools}
                                rewardRows={deactivatedRewards}
                                activeRewards={false}
                                rowsLoading={pageDataLoading}
                                handleClickAction={handleClickActions}
                            />
                        </div>
                    </div>
                </Grid>
            </Grid>
        </Box>
    )
}