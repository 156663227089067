import React, {useEffect, useState} from 'react';
import { Box, Button, CircularProgress, Grid, Tooltip, Typography} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import LaunchIcon from '@mui/icons-material/Launch';
import HelpIcon from '@mui/icons-material/Help';
import { isMobile } from 'react-device-detect';
import * as Sentry from "@sentry/react";

import {  TokenTypeDisplayData } from '../../../../../src/types/recognition';
import { School, UserData, UserKredsCount } from '../../../../../src/types/user';
import { Notifications } from './Notifications';
import SuccessPopup from './SuccessPopup';
import { authSelector } from '../../../store/authSlice';
import { RecognitionForm} from './RecognitionForm';
import { UserType } from '../../../util/type';
import { PermissionServiceApi } from '../../../services/permissionService';
import { DistrictRecognitionForm } from './DistrictRecognitionForm';
import UnsuccessfulPopup from './UnsuccessfulPopup';
import { UserServiceApi } from '../../../services/userService';
import { getFirstDayOfWeek } from '../../../util/time';

import './Recognition.css';

export interface TeacherRecognitionSubmission {
    teacherId?: number,
    recognitionText?: string,
    anonymous?: boolean,
    submittedByTeacherId?: number,
    coreTokenId?: number,
}

export interface RecognitionProps {
    userId: number;
    userKreds?: UserKredsCount;
    handleRecognitionSubmitted: () => void;
    previousLoginDate?: Date;
    school?: School;
    schoolTokenTypes: TokenTypeDisplayData[];
    schoolStaff: UserData[];
    rewardsPointsDisabled: boolean;
}

export function Recognition(props: RecognitionProps) {
    const {
        userId,
        userKreds,
        handleRecognitionSubmitted,
        school,
        schoolTokenTypes,
        schoolStaff,
        previousLoginDate,
        rewardsPointsDisabled
    } = props;
    // Navigation
    const navigate = useNavigate();
    
    const authDataStore = useSelector(authSelector);
	const { user, token } = authDataStore;

    // Logged in User
	if (!user) {
		navigate('/login');
	}
    const districtId = user!.districtId;

    const [successPopupOpen, setSuccessPopupOpen] = useState(false);
    const [bonusPointAwarded, setBonusPointAwarded] = useState<boolean | undefined>(false);
    const [unsuccessfulPopupOpen, setUnsuccsesfulPopupOpen] = useState(false);
    const [sendDistrictHilight, setSendDistrictHilight] = useState(false);
    const [countHilightsSent, setCountHilightsSent] = useState<number | undefined>();

    // District data
    const [allowCrossSchoolHilights, setAllowCrossSchoolHilights] = useState(false);
 
    // Check the district level permission for sending cross-school hilights
    useEffect(() => {
		if (districtId) {
			getDistrictLevelPermissions(districtId);
		}
	}, [districtId]);

	const getDistrictLevelPermissions = async (districtId: number) => {
		const permissionsServiceApi = PermissionServiceApi();

		try {
			const districtPermissions = await permissionsServiceApi.getPermissionsForDistrict(districtId, token!);
			const crossSchoolHilightsKey = 'CROSS_SCHOOL_HILIGHTS';
			const districtHasPermissionToSendCrossSchoolHilights = districtPermissions.find(permission => permission.permissionKey === crossSchoolHilightsKey);
			if (districtHasPermissionToSendCrossSchoolHilights) {
				setAllowCrossSchoolHilights(true);
			} else {
                setAllowCrossSchoolHilights(false);
            } 
		} catch (err) {
			// log the error
			Sentry.captureException(err);
		}
	}

    // Get the count of hilights sent this week by this user
    // Can't just base it off of how many points left they have to send in case they run out, and Admin have unliimited, so that # isn't reliable anymore
    const getCountHilightsSent = async (userId: number) => {
        try {
            const userServiceApi = UserServiceApi();
            // get the date of Monday this week
            const monday = getFirstDayOfWeek(new Date());
            const result = await userServiceApi.getUserHilightsSentCount({
                userId,
                token: token!,
                startDate: monday,
            });
            setCountHilightsSent(result);

        } catch (err) {
			// log the error
			Sentry.captureException(err);
		}
    }
    useEffect(() => {
        getCountHilightsSent(userId);
    }, [userId]);

    const handleRecognitionFormSubmitted = (result: {success: boolean, bonusPointAwarded?: boolean}): void => {
        if (result.success) {
            handleRecognitionSubmitted();
            getCountHilightsSent(userId);
            setBonusPointAwarded(result.bonusPointAwarded)
            setSuccessPopupOpen(true); 
        } else {
            setUnsuccsesfulPopupOpen(true);
        }
 
    }

    const handleClickDistrictHilightButton = (): void => {
        setSendDistrictHilight(!sendDistrictHilight);
    }

    const closeSuccessPopup = () => {
        setSuccessPopupOpen(false);
        setBonusPointAwarded(false);
    }
    const districtToolTipText = sendDistrictHilight 
        ? 'Click here to send a hilight within your school' 
        : 'Click here to send a hilight to someone at another school in your district.';

    const adminMotivationMessage = (
        <Box className='hilight-count-section'>
            <Typography className='hilight-count-section-text'> 
                You've sent <span className='number-of-hilights'>{countHilightsSent}</span> <span>{countHilightsSent === 1 ? 'hilight' : 'hilights'}</span> this week. 
                You have unlimited hilight points left to send.
                <Tooltip 
                title={
                    <div>
                        <Typography>
                            As an Admin user, you have unlimited hilight points to give. Every hilight you send, will also come with a point for the recipient.
                        </Typography>
                        <br />
                        <Typography>
                            {`The rest of your staff (standard users) start every week with 25 hilight points to give. Their points expire and refresh back to 25 every Sunday night, which serves as a little extra motivation to send at least 25 hilights a week! When they run out of points to give, they can still send a hilight. The recipient just won't earn a point.`}
                        </Typography>
                    </div>
                }
                >
                    <HelpIcon className='hilight-count-section-info-icon'/>
                </Tooltip>
            </Typography>
        </Box>
    );

    const teacherMotivationMessage = (
        <Box className='hilight-count-section'>
            <Typography className='hilight-count-section-text'> 
                You've sent <span className='number-of-hilights'>{countHilightsSent}</span> <span>{countHilightsSent === 1 ? 'hilight' : 'hilights'}</span> this week. 
                You have <span className='number-of-hilights'>{userKreds?.pointsToGive}</span> hilight points left to send to reach your weekly goal!
                <Tooltip 
                title={
                    <div>
                        <Typography>
                            Every week, you start off with 25 hilight points to give. Every time you send a hilight, it also comes with one point for the recipient! Your points to give will expire Sunday night, so give as many hilights as you can before then!
                        </Typography>
                        <br />
                        <Typography>
                            When you run out of points to give, don't worry! You can still send a hilight. The recipient just won't earn a point.
                        </Typography>
                        <br />
                        <Typography>
                            Points can be redeemed for rewards in the Rewards marketplace!
                        </Typography>
                    </div>
                }
                >
                    <HelpIcon className='hilight-count-section-info-icon'/>
                </Tooltip>
            </Typography>
        </Box>
    )

    return (
    <div className='recognition-page'>
        <Box className='page-heading'>Recognition</Box>
        {!rewardsPointsDisabled && userKreds &&
            (user?.userType === UserType.Admin ? adminMotivationMessage : teacherMotivationMessage)
        }
        <Box className='recognition-grid'>
            <SuccessPopup 
                popupOpen={successPopupOpen} 
                closePopup={closeSuccessPopup}
                bonusPointAwarded={bonusPointAwarded}
                rewardsDisabled={rewardsPointsDisabled}
            />
            <UnsuccessfulPopup popupOpen={unsuccessfulPopupOpen} closePopup={() => setUnsuccsesfulPopupOpen(false)} />
            <Grid container spacing={3}>
                {/* Send Recognition form */}
                <Grid item xs={7} md={7} lg={7} className='send-recognition-form'>
                    <div className='send-recognition-border'>
                        <div className={isMobile ? 'recognition-grid-item-mobile' : 'recognition-grid-item'}>
                            <div className='recognition-send-header'>
                                <h3 className='recognition-grid-item-header'>Send a hilight</h3>
                                {allowCrossSchoolHilights && 
                                    <div className='district-hilight-button'>
                                        <Tooltip arrow title={districtToolTipText}>
                                            <Button 
                                                sx={{color:'info.dark', borderColor:'info.dark'}}
                                                variant='outlined' size='small'
                                                endIcon={<LaunchIcon />}
                                                onClick={handleClickDistrictHilightButton}
                                            >
                                                {sendDistrictHilight ? 'My School' : 'District'}
                                            </Button>
                                        </Tooltip>
                                </div>
                                }
                            </div>
                            {!school 
                            ? <CircularProgress className='recognition-form-loading'/>
                            :
                            <div>
                                {sendDistrictHilight ?
                                    <DistrictRecognitionForm 
                                        userId={userId}
                                        districtId={districtId}
                                        handleRecognitionFormSubmitted={handleRecognitionFormSubmitted}
                                        userKreds={userKreds}
                                        currentSchoolId={school.id}
                                        rewardsPointsDisabled={rewardsPointsDisabled}
                                    />
                                : 
                                <RecognitionForm
                                    userId={userId}
                                    userKreds={userKreds}
                                    handleRecognitionFormSubmitted={handleRecognitionFormSubmitted}
                                    schoolId={school.id}
                                    tokenTypes={schoolTokenTypes}
                                    schoolStaff={schoolStaff}
                                    districtView={false}
                                    rewardsPointsDisabled={rewardsPointsDisabled}
                                />
                                }
                            </div>
                            
                        }    
                        </div>
                    </div>
                    
                </Grid>

                {/* Notifications */}
                <Grid item xs={4} md={4} lg={4} className='notifications-grid-item'>
                    <Notifications 
                        userId={userId} 
                        schoolId={school?.id} 
                        lastLoginDate={previousLoginDate}
                        currentPoints={userKreds?.currentPoints}
                        rewardsDisabled={rewardsPointsDisabled}
                    />
                </Grid>

                
            </Grid>
        </Box>
            
    </div>
    )
}