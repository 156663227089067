import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import * as Sentry from "@sentry/react";


import { School, UserData, UserKredsCount } from '../../../../../src/types/user';
import { MainServiceApi } from '../../../services/mainService';
import { authSelector } from '../../../store/authSlice';
import { TokenTypeDisplayData } from '../../../../../src/types/recognition';
import { Autocomplete, Box, CircularProgress, TextField, Typography } from '@mui/material';
import { RecognitionForm } from './RecognitionForm';

import './RecognitionForm.css'

export interface DistrictRecognitionFormProps {
    userId: number;
    districtId: number;
    handleRecognitionFormSubmitted: (result: {success: boolean, bonusPointAwarded?: boolean}) => void;
    userKreds?: UserKredsCount;
    currentSchoolId: number; // This is the school that the user is currently logged into. Different than "selectedSchool" below
    rewardsPointsDisabled: boolean;
}

export function DistrictRecognitionForm(props: DistrictRecognitionFormProps) {
    const {
        userId,
        districtId,
        handleRecognitionFormSubmitted,
        userKreds,
        currentSchoolId,
        rewardsPointsDisabled
    } = props;

    const authDataStore = useSelector(authSelector);
	const {token } = authDataStore;

    const [isLoadingFormFields, setIsLoadingFormFields] = useState(true);
    const [schoolList, setSchoolList] = useState<School[]>([]);
    const [selectedSchool, setSelectedSchool] = useState<School | null>();
    const [staffList, setStaffList] = useState<UserData[]>([]);
    const [districtTokens, setDistrictTokens] = useState<TokenTypeDisplayData[]>([]);


    const loadDistrictData = async (): Promise<void> => {
        setIsLoadingFormFields(true);

        try {
            const mainService = MainServiceApi();
            const schools = await mainService.getDistrictSchools(districtId, token!);
            if (schools) {
                // remove the user's current school (aka the school they have selected from the drop down in the side bar. If they don't belong to more than one school, then it's just their primary school)
                const filteredSchools = schools.filter(school => school.id !== currentSchoolId);
                // sort schools alhpabetically
                const alphabeticalSchools = _.sortBy(filteredSchools, 'name');
                setSchoolList(alphabeticalSchools);
                setSelectedSchool(alphabeticalSchools[0]);
            } else {
                setSchoolList([]);
            }

            const districtTokens = await mainService.getDistrictTokenTypes(districtId, token!);
            setDistrictTokens(districtTokens);
        } catch (err) {
            // log the error
			Sentry.captureException(err);
        } 

        setIsLoadingFormFields(false);
    }

    useEffect(() => {
        loadDistrictData();
    }, [districtId]);

    const loadSchoolStaffList = async (): Promise<void> => {
        // get the staff list
        try {
            if (selectedSchool) {
                const mainService = MainServiceApi();
                const staff = await mainService.getSchoolStaff(selectedSchool.id, token!);
                setStaffList(staff);
            } else {
                setStaffList([]);
            }
            
        }  catch (err) {
            // log the error
			Sentry.captureException(err);
        } 

    }

    useEffect(() => {
        loadSchoolStaffList();
    }, [selectedSchool]);

    return (
        <div className='district-recognition-form'>
            <div className='send-recognition-form-item'>
                <Autocomplete 
                    disablePortal
                    id='recognition-school-select'
                    options={schoolList || []}
                    getOptionLabel={(option) => option.name}
                    value={selectedSchool || null}
                    renderInput={(params) =>  <TextField {...params} label="Select a School in your District" />}
                    onChange={(event: any, value: School | null) => {
                        if (!value) {
                            setSelectedSchool(schoolList[0])
                        }
                        setSelectedSchool(value || null);
                    }}
                />
            </div>

            {isLoadingFormFields &&
                <Box className='recognition-form-loading'><CircularProgress/></Box>
            }
            {!selectedSchool ? 
                <Typography className='recognition-select-school-text'>First Select a School in your district</Typography>   
                :
                <RecognitionForm 
                    userId={userId}
                    userKreds={userKreds}
                    handleRecognitionFormSubmitted={handleRecognitionFormSubmitted}
                    schoolId={selectedSchool.id}  // Passes in the currently selected school, so recognition.schoolId will be the user receiver's school
                    tokenTypes={districtTokens}
                    schoolStaff={staffList}
                    districtView={true}
                    rewardsPointsDisabled={rewardsPointsDisabled}
                />
            }
        </div>
    )
}