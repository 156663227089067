import React, { useState, useEffect } from 'react';
import { Box, Button, Grid, Typography } from '@mui/material';
import * as Sentry from "@sentry/react";
import  * as _ from 'lodash';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import RefreshIcon from '@mui/icons-material/Refresh';

import { School, UserData } from '../../../../../../src/types/user';
import { ManageUsersTable } from './ManageUsersTable';
import { MainServiceApi } from '../../../../services/mainService';
import { useAppSelector } from '../../../../store/hooks';
import { authSelector } from '../../../../store/authSlice';

import './ManageUsersTab.css';
import EditUserPopup from './EditUserPopup';
import DeactivateUserPopup from './DeactivateUserPopup';

export interface ManageUsersTabProps {
    districtId: number;
}

export function ManageUsersTab(props: ManageUsersTabProps) {
    const { districtId } = props;
    const authDataStore = useAppSelector(authSelector);
    const {token} = authDataStore;

    const [pageDataLoading, setPageDataLoading] = useState(false);
    const [districtSchools, setDistrictSchools] = useState<School[]>([]);
    const [activeUsers, setActiveUsers] = useState<UserData[]>([]);
    const [deactivatedUsers, setDeactivatedUsers] = useState<UserData[]>([]);
    
    // Load the data for both tables
    useEffect(() => {

        loadTableData(districtId);
        
    }, [districtId]);

    const loadTableData = async (districtId: number) =>  {
        setPageDataLoading(true);
        try {
            const mainServiceApi = MainServiceApi();
            const districtSchoolsResult = await mainServiceApi.getDistrictSchools(districtId, token!)
            if (districtSchoolsResult) {
                setDistrictSchools(districtSchoolsResult);
            } else {
                setDistrictSchools([]);
                throw new Error();
            }

            const activeDistrictUsersResult = await mainServiceApi.getDistrictUsers({districtId, token: token!})
            if (activeDistrictUsersResult) {
                setActiveUsers(activeDistrictUsersResult)
            } else {
                setActiveUsers([]);
            }
            
            const deactivatedResult = await mainServiceApi.getDeactivatedDistrictUsers({districtId, token: token!});
            if (deactivatedResult) {
                setDeactivatedUsers(deactivatedResult)
            } else {
                setDeactivatedUsers([]);
            }
        } catch (err) {
            // log the error
            Sentry.captureException(err);
        }
        setPageDataLoading(false);
    }

    // Table Action Popups: 
    const [selectedUser, setSelectedUser] = useState<UserData | undefined >();
    const [showEditUserPopup, setShowEditUserPopup] = useState(false);
    const [showDeactivateUserPopup, setShowDeactivateUserPopup] = useState(false);
    const [showReactivateUserPopup, setShowReactivateUserPopup] = useState(false);    
    const handleClickActions = (data: {user?: UserData, deactivate?: boolean, reactivate?: boolean}) => {
        setSelectedUser(data.user);
        if (data.deactivate) {
            setShowDeactivateUserPopup(true);
        } else if (data.reactivate) {
            setShowReactivateUserPopup(true);
        } else {
            setShowEditUserPopup(true);
        }
    }
    const closePopup = () => {
        setSelectedUser(undefined);
        // We can use the same function for all three popups, so just close all
        setShowEditUserPopup(false);
        setShowDeactivateUserPopup(false);
        setShowReactivateUserPopup(false);
    }
    
    const handleResetUpdatedUser = (data: {user: UserData, newlyCreatedUser: boolean}) => {
        // If the user was created, add them to the Current Users table and sort by last name
        if (data.newlyCreatedUser) {
            const newRows = activeUsers.concat(data.user);
            setActiveUsers(_.sortBy(newRows, 'lastName'));
        } else  {
            // Update the row for the user so it shows the new data
            const newRows = activeUsers.map((row) => {
                if (row.id === data.user.id) {
                    return data.user;
                } else {
                    return row;
                }
            });
            setActiveUsers(newRows);
        }
        
    }

    const handleResetDeactivatedUser = (deactivatedUser: UserData) => {
        // Remove the deactivated user from the Current Users table
        const newRows = activeUsers.filter(row => row.id !== deactivatedUser.id);
        setActiveUsers(newRows);

        // Add the deactivated user to Deactivated Users table and sort by last name
        const newDeactivatedUsers = deactivatedUsers.concat(deactivatedUser);
        setDeactivatedUsers(_.sortBy(newDeactivatedUsers, 'lastName'));
    }

    const handleResetReactivatedUser = (data: {user: UserData}) => {
        // Remove the reactivated User from the Deactivated Users table
        const newDeactivatedRows = deactivatedUsers.filter(row => row.id !== data.user.id);
        setDeactivatedUsers(newDeactivatedRows);
        
        // Add the reactivated user to the Current Users table and sort by last name again
        const newActiveRows = activeUsers.concat(data.user);
        setActiveUsers(_.sortBy(newActiveRows, 'lastName'));
    }


    return (
        <Box>
            <EditUserPopup 
                userToUpdate={selectedUser}
                districtSchools={districtSchools}
                popupOpen={showEditUserPopup} 
                closePopup={closePopup}
                resetUser={handleResetUpdatedUser}
                reactivateUser={false}
                districtId={districtId}
            />
            <DeactivateUserPopup 
                userToDeactivate={selectedUser}
                popupOpen={showDeactivateUserPopup}
                closePopup={closePopup}
                handleRemoveUser={handleResetDeactivatedUser}
            />
            {/* Reactivate User Popup (also uses Edit User Popup) */}
            <EditUserPopup 
                userToUpdate={selectedUser}
                districtSchools={districtSchools}
                popupOpen={showReactivateUserPopup} 
                closePopup={closePopup}
                resetUser={handleResetReactivatedUser}
                reactivateUser={true}
                districtId={districtId}
            />
            <Grid container spacing={3}>
                <Grid item xs={12} md={12} className='admin-settings-grid-item'>
                    <div className='admin-settings-grid-item-border'>
                        <div className='admin-settings-grid-item-content'>
                            <Box className='current-users-header'>
                                <h3 className='admin-settings-grid-item-header'>Current Users</h3>
                                <div className='create-user-button-div'>
                                    <Button 
                                        id='create-user-button' 
                                        variant='contained'
                                        startIcon={<PersonAddIcon />}
                                        onClick={() => handleClickActions({})}
                                    >Create User</Button>
                                </div>
                            </Box>
                            <Typography className='current-users-instructions'>
                                You can use this table to 
                                <span className='create-users-bold-text'> Update </span>{`(`}<EditIcon sx={{fontSize: 'small'}}/>{`) or `}<span className='create-users-bold-text'>Deactivate</span>{` (`}<DeleteIcon sx={{fontSize: 'small'}}/>{`) `}
                                any of your current users. You can search for a specific user by hovering over the column names and selecting the "Filter" or "Sort" options from the menu that appears.
                            </Typography>
                            <ManageUsersTable 
                                districtSchools={districtSchools}
                                userRows={activeUsers}
                                activeUsers={true}
                                rowsLoading={pageDataLoading}
                                handleClickAction={handleClickActions}
                            />
                        </div>
                    </div>
                </Grid>
                <Grid item xs={12} md={12} className='admin-settings-grid-item'>
                    <div className='admin-settings-grid-item-border'>
                        <div className='admin-settings-grid-item-content'>
                            <h3 className='admin-settings-grid-item-header'>Deactivated Users</h3>
                            <Typography className='current-users-instructions'>
                                You can use this table to 
                                <span className='create-users-bold-text'> Reactivate </span>{`(`}<RefreshIcon sx={{fontSize: 'small'}}/>{`) `}
                                any users that were previously deactivated. All hilights and points that they sent or received will be retained. You can search for a specific user by hovering over the column names and selecting the "Filter" or "Sort" options from the menu that appears.
                            </Typography>
                            <ManageUsersTable 
                                districtSchools={districtSchools}
                                userRows={deactivatedUsers} 
                                activeUsers={false}
                                rowsLoading={pageDataLoading}
                                handleClickAction={handleClickActions}
                            />
                        </div>
                    </div>
                </Grid>
            </Grid>
        </Box>
    )
}